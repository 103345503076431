import { useEffect, useState, useRef, useContext, PropsWithChildren, useCallback } from 'react';
import 'styles/RoomPlanner.css';
import { useHistory } from 'react-router-dom';
import {
  getRoomConfig,
  getRoomInfo,
  getShowTooltip,
  getTemplatesData,
  getUniversityId,
  saveRoomConfig,
  saveShowTooltip,
} from 'context/localStorage';
import {
  Snackbar,
  CircularProgress,
  CopyButton,
  CustomButton,
  CustomIconButtonSecondary,
  RadioField,
  InputFieldWithLabel,
  UniversityCatalog,
  PhotoViewerDialog,
  CancelIcon,
  EditConfigDialog,
  RightMeasurementButton,
  RoomViewerOnScreenText,
  // StyleIcon,
} from 'components';
import Drawer from '@material-ui/core/Drawer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Routes from 'routes';
import useStyles from 'styles/templateStyles';
import {
  layoutConfigToRoomItems,
  roomSceneToLayoutItems,
  isBunk,
  isBedFrameType,
  MATTRESS_ANCHOR,
  BUNK_ANCHOR,
  isMattressType,
  isPillowType,
  Messages,
  GET,
  POST,
  ROOM_SAVED_SUCCESS,
  ROOM_RESET_SUCCESS,
  ROOM_CONFIGURATIONS,
  getOrigin,
  STATUS_OPTIONS,
} from 'utils';
import {
  LayoutItem,
  UniversityCatalogProps,
  UniversityCatalogItemProps,
  Toast,
  ConfigsType,
  GuideView,
  ConfigStyle,
} from 'types';
import useAxios, { ServerRoutes } from 'api';
import { AxiosError } from 'axios';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Colors from 'theme';
import { v4 } from 'uuid';
import UpDownKeys from 'assets/upDownKeys.png';
import { ItemModel, ItemModelSubType, ItemModelType, RoomScene } from '@roomie-engineering/room-viewer';
import { AuthContext } from 'context/authContext';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { RoomViewer } from 'components/RoomViewer';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Button as ButtonMUI,
  IconButton,
  Modal,
  Tab,
  Tabs,
  makeStyles,
} from '@material-ui/core';
import { Delete, Info } from '@material-ui/icons';
import { sortBy } from 'lodash-es';
import AsyncAutocomplete from 'components/autocomplete/AsyncAutocomplete';
import AppliedStyles from 'components/lists/AppliedStyles';

interface ButtonProps {
  background?: string;
  border?: string;
  title: string;
  onClick: () => void;
  solid?: boolean;
  width?: string;
  borderRadius?: string;
  enable?: boolean;
}

const initialConfigs = {
  bedConfig: '',
  standardBed: '',
  loftBed: '',
  bunkBed: '',
  studentCount: '',
};

const initialConfig = {
  modelUrl: '',
  panoUrl: '',
  roomDescription: '',
  configName: '',
  friendlyName: '',
  typeName: '',
};

const SHOW_BED_TOGGLE_CONFIGURATIONS = false;

const STYLE_INITIAL = {
  styleId: '',
  configId: '',
  configStyleItems: [],
};

const AdminPortal = (): JSX.Element => {
  const [tabValue, setTabValue] = useState(0);

  const classes = useStyles();
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const [, dummyState] = useState(false);
  const refresh = useCallback(() => dummyState((prev) => !prev), []);

  const [roomSceneState, setRoomSceneState] = useState<RoomScene | null>(null);
  const [catalogs, setCatalogs] = useState<UniversityCatalogProps[]>([]);
  const [showButton, setShowButton] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [status, setStatus] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showEditConfigModal, setShowEditConfigModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [toast, setToast] = useState<Toast>();
  const [showTooltip, setShowTooltip] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isMeasuring, setIsMeasuring] = useState(false);
  const [clearMeasurementHovered, setClearMeasurementHovered] = useState(false);
  const [isBedItem, setIsBed] = useState(false);
  const [token, setToken] = useState('');
  const rConfig = getRoomConfig();
  const roomInfo = getRoomInfo();
  const [roomConfigs, setRoomConfigs] = useState<ConfigsType>(initialConfigs);
  const [onScreenTextvalue, setOnScreenTextValue] = useState<string>('');
  const [configInfo, setConfigInfo] = useState(initialConfig);
  const [previousTemplate, setPreviousTemplate] = useState<GuideView | null>(null);
  const [nextTemplate, setNextTemplate] = useState<GuideView | null>(null);
  const [showMeasureButton, setShowMeasureButton] = useState(false);
  const [isEnableCatalogList, setIsEnableCatalogList] = useState<boolean>(false);
  const [style, setStyle] = useState<any>(STYLE_INITIAL);
  const [activeMode, setActiveMode] = useState<'CreateStyleAssociation' | 'TemplateView' | 'UpdateStyleAssociation'>(
    'TemplateView',
  );
  const [appliedStyles, setAppliedStyles] = useState<ConfigStyle | null>(null);
  const [styleItemIds, setStyleItemIds] = useState<any>([]);

  const roomSceneStateRef = useRef(roomSceneState);
  const listRef = useRef<HTMLDivElement>(null);

  const setRoomSceneStateRef = (roomScene: RoomScene | null) => {
    if (roomScene) {
      setRoomSceneState(roomScene);
      roomSceneStateRef.current = roomScene;
    }
  };

  const params = new URLSearchParams(window.location.search);
  const isLocked = params.get('isLocked');

  const {
    callApi: loadUniversityCatalogs,
    loading: catalogsLoading,
    error: catalogsError,
  } = useAxios(ServerRoutes.adminLoadUniversityCatalogs, POST, false);

  const {
    callApi: loadLayout,
    response: loadLayoutRes,
    loading: loadLayoutLoading,
    error: loadLayoutError,
  } = useAxios(ServerRoutes.adminLoadLayout, GET, false);

  const {
    callApi: saveLayout,
    loading: saveLayoutLoading,
    error: saveLayoutError,
  } = useAxios(ServerRoutes.adminSaveLayout, POST, false);

  const { callApi: createAssociationApi } = useAxios(ServerRoutes.createAssociation, POST, false);
  // const { callApi: createAssociationApi } = useAxios(ServerRoutes.createAssociation, GET, false);

  const {
    // response: rooms,
    callApi: loadRooms,
    loading: roomsLoading,
    error: roomsError,
  } = useAxios(ServerRoutes.adminRoomsByConfig, POST, false);

  const {
    callApi: editConfig,
    loading: editConfigLoading,
    error: editConfigError,
  } = useAxios(ServerRoutes.adminEditConfig, POST, false);

  const {
    callApi: updateRoomStatus,
    loading: updateStatusLoading,
    error: updateStatusError,
  } = useAxios(ServerRoutes.adminUpdateStatus, POST, false);

  const { callApi: getAssociatedTepmplateStyles } = useAxios(ServerRoutes.createAssociation, GET, false);

  const getAssociatedStyles = async () => {
    await getAssociatedTepmplateStyles({ configId: rConfig?.roomieRoomConfigId }, (styles) => {
      if (styles?.data) {
        const filteredStyles: any = styles?.data?.filter((style: any) => style.styleId && style.styleName);
        setAppliedStyles(filteredStyles);
      }
    });
  };

  useEffect(() => {
    if (!rConfig.roomieRoomConfigId) history.push(Routes.DEFAULT);
    const fetchCatalogs = async () => {
      const id = getUniversityId();
      await loadUniversityCatalogs(
        {
          university_id: id ? id : roomInfo.orgId,
        },
        (catalogsResp) => {
          if (catalogsResp.data?.catalogs) {
            setCatalogs(catalogsResp.data.catalogs);
          }
        },
      );
    };
    fetchCatalogs();

    const loadItems = async () => {
      await loadAdminLayout(rConfig);
      setToken(rConfig.token);
      window.addEventListener('keydown', keyPressEventListener);
    };
    if (rConfig.roomieRoomConfigId) {
      loadItems();
    }
    getAssociatedStyles();
    return function cleanUp() {
      window.removeEventListener('keydown', keyPressEventListener);
    };
  }, []);

  useEffect(() => {
    const loadHeights = async () => {
      if (roomSceneState) await applyHeight(roomSceneState, loadLayoutRes?.data.templateItems);
    };
    if (isLoaded) setTimeout(() => loadHeights(), 1000);
  }, [isLoaded]);

  useEffect(() => {
    const showError = (error: AxiosError | undefined) => {
      if (error) {
        setShowToast(true);
        setToast({
          severity: 'error',
          message: error.message,
          autoHideDuration: 3000,
        });
      }
    };
    showError(loadLayoutError);
    showError(updateStatusError);
    showError(saveLayoutError);
    showError(catalogsError);
    showError(roomsError);
    showError(editConfigError);
  }, [loadLayoutError, saveLayoutError, catalogsError, roomsError, editConfigError, updateStatusError]);

  const hideTooltip = () => {
    saveShowTooltip('false');
    setShowTooltip(false);
  };

  const keyPressEventListener = (evt: KeyboardEvent) => {
    if (roomSceneStateRef.current) {
      const selectedItem = roomSceneStateRef?.current?.selectedRoom?.selectedItem;
      if (selectedItem) {
        const bedFeature = selectedItem.getBedFeature();
        if (bedFeature) {
          if (evt.key == 'ArrowUp') {
            bedFeature.adjustFrameHeight(true);
            hideTooltip();
          } else if (evt.key == 'ArrowDown') {
            bedFeature.adjustFrameHeight(false);
            hideTooltip();
          }
        }
      }
    }
  };

  const loadAdminLayout = async (currentConfig: GuideView, changingTemplate = false) => {
    const templatesData = getTemplatesData();
    await loadLayout({ id: currentConfig.roomieRoomConfigId, token: '' }, async (loadLayoutResp) => {
      if (loadLayoutResp && loadLayoutResp.data.code === 0) {
        const data = loadLayoutResp?.data;
        setStatus(data?.status || 'Active');
        roomInfo.admin && (await loadRooms({ roomie_room_config_id: data?.roomieRoomConfigId }));
        const config = {
          modelUrl: data.roomie_model_url || '',
          panoUrl: data?.panoUrl || '',
          roomDescription: data?.roomDescription || '',
          configName: data?.roomieConfigName || '',
          friendlyName: data?.friendlyName || '',
          typeName: data?.type || '',
        };
        setConfigInfo(config);
        let next: GuideView | null = null,
          previous: GuideView | null = null;
        templatesData.forEach((item, index) => {
          if (templatesData[index].roomieRoomConfigId === data?.roomieRoomConfigId) {
            if (index > 0) previous = templatesData[index - 1];
            if (index < templatesData.length - 1) next = templatesData[index + 1];
          }
        });
        setNextTemplate(next);
        setPreviousTemplate(previous);
        if (roomSceneState && changingTemplate) {
          saveRoomConfig(currentConfig);
          const room = roomSceneState.addRoom({
            title: data?.building_name || '',
            assetUrl: data?.roomie_model_url,
            isLocked: isLocked === 'true' && !roomInfo.admin,
            setIsLoaded: setIsLoaded,
          });
          room.addItems(layoutConfigToRoomItems(data?.templateItems));
          roomSceneState.setSelectedRoom(room);
        }
      }
    });
  };

  const copy = () => {
    const origin = getOrigin();
    const url = `${origin}${Routes.TEMPLATE}?id=${token}`;
    const textField = document.createElement('textarea');
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    if (navigator) navigator.clipboard.writeText(url);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 10000);
  };

  const setValueInRoomConfigs = (key: string, value: string | number) => {
    setRoomConfigs((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const notBed = () => {
    setIsBed(false);
    setShowTooltip(false);
  };

  const scrollToProduct = (productId: string) => {
    const listItem = document.querySelector(`#item-${productId}`);
    if (listItem) {
      listItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const onItemSelected = (item?: ItemModel) => {
    if (item) {
      if (item?.props?.catalogId) {
        scrollToProduct(item?.props?.catalogId);
      }
      if (item.type == ItemModelType.Bed) {
        setIsBed(true);
        if (getShowTooltip() === 'true') {
          setShowTooltip(true);
        }
      } else {
        notBed();
      }
      setShowButton(true);
    } else {
      setShowButton(false);
      notBed();
    }
  };

  const clearMeasurementPressed = () => {
    setIsMeasuring(false);
    roomSceneState?.measureTool.deactivate();
  };

  const onMeasureTool = (active: boolean, count?: number): void => {
    if (active) {
      setIsMeasuring(true);
      setShowMeasureButton(true);
      count != undefined
        ? count == 0
          ? setOnScreenTextValue('Click another point to make a measurement')
          : setOnScreenTextValue('')
        : setOnScreenTextValue('Click anywhere to start a measurement');
    } else {
      setIsMeasuring(false);
      setShowMeasureButton(false);
      setOnScreenTextValue('');
    }
  };

  const applyHeight = async (scene: RoomScene, template: LayoutItem[]) => {
    if (scene) {
      const beds = scene.selectedRoom?.getAllItems().filter((item) => isBedFrameType(item.type || ''));
      beds?.forEach((item) => {
        const bedItem = template.filter(
          (resItem: LayoutItem) =>
            (resItem.parentItemId === item.itemId || resItem.itemId === item.itemId) &&
            isBedFrameType(resItem?.itemDetail?.type || ''),
        );
        if (bedItem) {
          renderBedWithHeight(bedItem[0].height ?? -1, item);
        }
      });
    }
  };

  const renderBedWithHeight = async (height: number, bed: ItemModel) => {
    if (height > -1) {
      const feature = bed.getBedFeature();
      feature?.setFrameHeight(height);
    }
    setIsLoaded(false);
  };

  const getBeds = () => {
    const beds =
      roomSceneState?.selectedRoom?.getAllItems().filter(
        (item) => item.type === ItemModelType.Bed, // && item.subType !== 'Double',
      ) || [];
    return beds;
  };

  const getBedsCount = () => {
    const beds = getBeds();
    let count = 0;
    for (let i = 0; i < beds?.length; i++) {
      count++;
      if (beds[i].subType === ItemModelSubType.TwinXLBunk) count++;
    }
    return count;
  };

  const saveAllowed = () => {
    if (!roomConfigs.bunkBed || !roomConfigs.standardBed || !roomConfigs.loftBed || !roomConfigs.bedConfig) {
      setIsError(true);
      setShowToast(true);
      setToast({
        severity: 'error',
        message: Messages.ROOM_CONFIG,
        autoHideDuration: 3000,
      });
      return false;
    }
    if (
      roomConfigs.studentCount > 10 ||
      roomConfigs.studentCount < 0 ||
      (!roomConfigs.studentCount && roomConfigs.studentCount !== 0)
    ) {
      setIsError(true);
      setShowToast(true);
      setToast({
        severity: 'error',
        messageHeader: Messages.STUDENT_COUNT_HEADER,
        message: Messages.STUDENT_COUNT,
        autoHideDuration: 3000,
      });
      return false;
    }
    const count = getBedsCount();
    if (count > roomConfigs.studentCount || count < roomConfigs.studentCount) {
      setIsError(true);
      setShowToast(true);
      setToast({
        severity: 'error',
        messageHeader: Messages.BED_COUNT_ERROR_HEADER,
        message: Messages.BED_COUNT_ERROR,
        autoHideDuration: 3000,
      });
      return false;
    }
    const beds = getBeds();
    let standardBed = false;
    let loftBed = false;
    let bunkBed = false;
    let mattressError = false;
    beds.forEach((bed) => {
      if (bed.getChildren().length < 1) mattressError = true;
      if (bed.subType === ItemModelSubType.TwinXLBunk && roomConfigs.bunkBed !== 'Yes') bunkBed = true;
      if (bed.subType === ItemModelSubType.TwinXL && roomConfigs.standardBed !== 'Yes') standardBed = true;
      if (bed.subType === ItemModelSubType.TwinXLLoft && roomConfigs.loftBed !== 'Yes') loftBed = true;
    });
    if (standardBed) {
      setShowToast(true);
      setToast({
        severity: 'error',
        messageHeader: Messages.STANDARD_NOT_ALLOWED_HEADER,
        message: Messages.REMOVE_STANDARD,
        autoHideDuration: 3000,
      });
      return false;
    }
    if (loftBed) {
      setShowToast(true);
      setToast({
        severity: 'error',
        messageHeader: Messages.LOFT_NOT_ALLOWED_HEADER,
        message: Messages.REMOVE_LOFT,
        autoHideDuration: 3000,
      });
      return false;
    }
    if (bunkBed) {
      setShowToast(true);
      setToast({
        severity: 'error',
        messageHeader: Messages.BUNK_NOT_ALLOWED_HEADER,
        message: Messages.REMOVE_BUNKS,
        autoHideDuration: 3000,
      });
      return false;
    }
    if (mattressError) {
      setShowToast(true);
      setToast({
        severity: 'error',
        message: Messages.MATTRESS_ERROR,
        autoHideDuration: 3000,
      });
      return false;
    }
    setIsError(false);
    return true;
  };

  const addStyleItem = async (selectedStyle?: any, styleItem?: any) => {
    const tags = styleItem?.descriptorTag || styleItem?.itemDetail;
    if (styleItem) {
      const itemData = {
        itemId: activeMode === 'CreateStyleAssociation' ? v4() : styleItem?.id,
        catalogId: styleItem.catalogId || '',
        variantId: styleItem.variantId,
        assetUrl: tags?.url,
        deletable: styleItem?.deletable,
        moveable: true,
        parentItemId: styleItem?.itemId,
        anchor: styleItem?.anchor,
        type: tags?.type || '',
        subType: tags?.subType || '',
        surfaceType: tags?.surfaceType || '',
        position: [styleItem?.positionX, styleItem?.positionY, styleItem?.positionZ],
        rotation: [styleItem?.rotationX, styleItem?.rotationY, styleItem?.rotationZ],
      };
      setStyleItemIds((prevIds: any) => [...prevIds, itemData.itemId]);
      roomSceneState?.selectedRoom?.addItem(itemData, false);
      setStyle((prevState: any) => {
        const updatedTemplateStyleItems = [...(prevState.configStyleItems || [])];
        const newItem = {
          id: itemData?.itemId,
          configId: rConfig?.roomieRoomConfigId,
          styleItem: { id: activeMode === 'CreateStyleAssociation' ? styleItem?.id : styleItem?.styleItemId },
          deletable: itemData?.deletable,
          moveable: itemData?.moveable,
          parentItemId: itemData?.parentItemId,
          anchor: itemData?.anchor,
          active: true,
        };
        updatedTemplateStyleItems.push(newItem);
        return {
          ...prevState,
          configId: rConfig?.roomieRoomConfigId,
          configStyleItems: updatedTemplateStyleItems,
          styleId: selectedStyle?.id,
          configStyleId: selectedStyle?.id,
        };
      });
    }
  };

  const addItem = async (catalog: UniversityCatalogItemProps | any) => {
    let materials;
    const tags = catalog?.descriptorTags || catalog?.descriptorTag || catalog?.itemDetail;
    if (tags?.materials) materials = JSON.parse(tags.materials);

    if (isMattressType(tags?.type || '')) {
      if (!isBedItem) {
        setShowToast(true);
        setToast({
          severity: 'error',
          message: Messages.SELECT_BED,
          autoHideDuration: 3000,
        });
      } else {
        const selectedItem = roomSceneState?.selectedRoom?.selectedItem;
        const children = selectedItem?.getChildren() || [];
        const bunkItem = isBunk(selectedItem?.subType || '');
        const j = bunkItem ? 2 : 1;
        for (let i = 0; i < j; i++) {
          if (children?.length > 0) roomSceneState?.selectedRoom?.removeItem(children[i], false);
          const itemData = {
            itemId: v4(),
            catalogId: catalog.catalogId,
            variantId: catalog.catalogItemId,
            assetUrl: tags?.url || '',
            deletable: true,
            moveable: true,
            parentItemId: selectedItem?.itemId,
            anchor: i === 0 ? MATTRESS_ANCHOR : BUNK_ANCHOR,
            type: tags?.type || '',
            subType: tags?.subType || '',
            surfaceType: tags?.surfaceType || '',
            materials: materials,
          };
          roomSceneState?.selectedRoom?.addItem(itemData, false);
        }
      }
    } else {
      const count = getBedsCount();
      if (
        ((count === roomConfigs.studentCount || count > roomConfigs.studentCount) &&
          tags?.type === ItemModelType.Bed &&
          SHOW_BED_TOGGLE_CONFIGURATIONS) ||
        (count === Number(roomConfigs.studentCount) - 1 &&
          tags?.subType === ItemModelSubType.TwinXLBunk &&
          SHOW_BED_TOGGLE_CONFIGURATIONS)
      ) {
        setIsError(true);
        setShowToast(true);
        setToast({
          severity: 'error',
          messageHeader: Messages.BED_COUNT_HEADER,
          message: Messages.BED_COUNT,
          autoHideDuration: 3000,
        });
      } else if (
        tags?.subType === ItemModelSubType.TwinXLBunk &&
        roomConfigs.bunkBed !== 'Yes' &&
        SHOW_BED_TOGGLE_CONFIGURATIONS
      ) {
        setShowToast(true);
        setToast({
          severity: 'error',
          messageHeader: Messages.BUNK_NOT_ALLOWED,
          message: Messages.BUNK_NOT_ALLOWED_HEADER,
          autoHideDuration: 3000,
        });
      } else if (
        tags?.subType === ItemModelSubType.TwinXLLoft &&
        roomConfigs.loftBed !== 'Yes' &&
        SHOW_BED_TOGGLE_CONFIGURATIONS
      ) {
        setShowToast(true);
        setToast({
          severity: 'error',
          messageHeader: Messages.LOFT_NOT_ALLOWED_HEADER,
          message: Messages.LOFT_NOT_ALLOWED,
          autoHideDuration: 3000,
        });
      } else if (
        tags?.subType === ItemModelSubType.TwinXL &&
        tags.type === ItemModelType.Bed &&
        roomConfigs.standardBed !== 'Yes' &&
        SHOW_BED_TOGGLE_CONFIGURATIONS
      ) {
        setShowToast(true);
        setToast({
          severity: 'error',
          messageHeader: Messages.STANDARD_NOT_ALLOWED_HEADER,
          message: Messages.STANDARD_NOT_ALLOWED,
          autoHideDuration: 3000,
        });
      } else {
        setIsError(false);
        const itemProps = {
          itemId: v4(),
          variantId: catalog.catalogItemId || catalog?.variantId,
          catalogId: catalog.catalogId,
          assetUrl: tags?.url,
          deletable: true,
          moveable: true,
          type: tags?.type || '',
          subType: tags?.subType || '',
          surfaceType: tags?.surfaceType || '',
          materials: materials,
          place: true,
        };
        roomSceneState?.selectedRoom?.addItem(itemProps, true);
      }
    }

    refresh();
  };

  const removeElement = async () => {
    const selectedItem = roomSceneState?.selectedRoom?.selectedItem;
    if (selectedItem) {
      if (selectedItem.type === ItemModelType.Bed) {
        const children = selectedItem?.getChildren() || [];
        for (let i = 0; i < children?.length; i++) roomSceneState?.selectedRoom?.removeItem(children[i], false);
      }
      roomSceneState?.selectedRoom?.removeItem(selectedItem, false);
      setIsError(false);
    }

    refresh();
  };

  const saveRoom = async () => {
    let isSaveAllowed = true;
    if (SHOW_BED_TOGGLE_CONFIGURATIONS) isSaveAllowed = saveAllowed();
    if (isSaveAllowed) {
      const layout = roomSceneToLayoutItems(roomSceneState);
      await saveLayout(
        {
          roomie_room_config_id: loadLayoutRes?.data?.roomieRoomConfigId,
          templateItems: layout,
        },
        async (saveRoomResp) => {
          if (saveRoomResp.data.code == 0) {
            if (activeMode === 'CreateStyleAssociation') {
              setActiveMode('TemplateView');
              getAssociatedStyles();
              resetRoom();
            }
            await loadLayout({ id: loadLayoutRes?.data?.roomieRoomConfigId, token: '' });
            setToast({
              severity: 'success',
              message: ROOM_SAVED_SUCCESS,
              autoHideDuration: 3000,
            });
            setShowToast(true);
          }
        },
      );
    }
  };

  const createAssociateStyle = async () => {
    let isSaveAllowed = true;
    if (SHOW_BED_TOGGLE_CONFIGURATIONS) isSaveAllowed = saveAllowed();
    if (isSaveAllowed) {
      const layout = roomSceneToLayoutItems(roomSceneState);
      const filteredLayout = layout.filter((item: LayoutItem) => styleItemIds.includes(item?.itemId));

      const updatedStyleItems = style.configStyleItems.map((styleItem: any) => {
        const matchingLayoutItem = filteredLayout.find((item: any) => item.itemId === styleItem.id);
        if (matchingLayoutItem) {
          return {
            ...styleItem,
            positionX: matchingLayoutItem.positionX,
            positionY: matchingLayoutItem.positionY,
            positionZ: matchingLayoutItem.positionZ,
            rotationX: matchingLayoutItem.rotationX,
            rotationY: matchingLayoutItem.rotationY,
            rotationZ: matchingLayoutItem.rotationZ,
            deletable: true,
          };
        }
        return styleItem;
      });
      const appliedStylePayload = {
        styleId: style?.styleId,
        configId: style?.configId,
        ...(activeMode === 'UpdateStyleAssociation' && { configStyleId: style?.configStyleId }),
        configStyleItems: updatedStyleItems,
      };

      await createAssociationApi(appliedStylePayload, async (saveRoomResp) => {
        if (saveRoomResp.data.code == 0) {
          if (activeMode === 'CreateStyleAssociation' || activeMode === 'UpdateStyleAssociation') {
            setActiveMode('TemplateView');
            getAssociatedStyles();
            resetRoom(true);
            setToast({
              severity: 'success',
              message: ROOM_SAVED_SUCCESS,
              autoHideDuration: 3000,
            });
            setShowToast(true);
          }
          await loadLayout({ id: loadLayoutRes?.data?.roomieRoomConfigId, token: '' });
          setStyle({ ...STYLE_INITIAL });
        }
      });
    }
  };

  const resetRoom = (disableMessage?: boolean) => {
    if (loadLayoutRes && roomSceneState) {
      setIsError(false);
      setIsLoaded(false);
      const room = roomSceneState.addRoom({
        title: loadLayoutRes?.data?.building_name || '',
        assetUrl: loadLayoutRes?.data?.roomie_model_url,
        isLocked: isLocked === 'true' && !roomInfo.admin,
        setIsLoaded: setIsLoaded,
      });
      room.addItems(layoutConfigToRoomItems(loadLayoutRes?.data?.templateItems));
      roomSceneState.setSelectedRoom(room);
      if (disableMessage) {
        return;
      }
      setToast({
        severity: 'success',
        message: ROOM_RESET_SUCCESS,
        autoHideDuration: 3000,
      });
      setShowToast(true);
    }

    refresh();
  };

  // const removeRoomItems = () => {
  //   const layout = roomSceneToLayoutItems(roomSceneState);
  //   layout?.forEach((item: any) => {
  //     const itemModel = roomSceneState?.selectedRoom?.getItemById(item?.itemId);
  //     if (itemModel) {
  //       roomSceneState?.selectedRoom?.removeItem(itemModel, false);
  //     }
  //   });
  // };

  const closeDialog = () => {
    const data = loadLayoutRes?.data;
    const config = {
      modelUrl: data.roomie_model_url || '',
      panoUrl: data?.panoUrl || '',
      roomDescription: data?.roomDescription || '',
      configName: data?.roomieConfigName || '',
      friendlyName: data?.friendlyName || '',
      typeName: data?.type || '',
    };
    setConfigInfo(config);
    setShowEditConfigModal(false);
  };

  const saveConfig = async () => {
    const config = {
      configId: loadLayoutRes?.data?.roomieRoomConfigId,
      modelUrl: configInfo.modelUrl,
      type: configInfo.typeName,
      panoUrl: configInfo.panoUrl,
      roomConfigName: configInfo.configName,
      friendlyName: configInfo.friendlyName,
      roomDescriptionBlurb: configInfo.roomDescription,
    };
    await editConfig(config);
    await loadLayout({ id: loadLayoutRes?.data?.roomieRoomConfigId, token: '' });
  };

  const updateStatus = async (status: string) => {
    setStatus(status);
    await updateRoomStatus({ status, configId: loadLayoutRes?.data?.roomieRoomConfigId });
  };

  const renderTemplate = async (config: GuideView) => {
    setIsLoaded(false);
    setToken(config.token);
    await loadAdminLayout(config, true);
  };

  const loadLayoutOnDelete = async () => {
    await loadAdminLayout(rConfig);
  };

  useEffect(() => {
    if (activeMode !== 'TemplateView') {
      setIsEnableCatalogList(true);
      return;
    }
    setIsEnableCatalogList(false);
  }, [activeMode]);

  const CustomTooltip = () => (
    <div className={classes.tooltip}>
      <img src={UpDownKeys} className={classes.arrowKeys} />
      Select bed then tap the up/down keys to customize bed height
    </div>
  );

  const Button = ({
    background = 'none',
    border = 'none',
    solid = false,
    title,
    borderRadius = '34px',
    onClick,
    width = '230px',
    enable = true,
  }: ButtonProps) => (
    <CustomButton
      title={title}
      width={width}
      height="48px"
      borderRadius={borderRadius}
      mTop="0px"
      mBottom="21px"
      buttonClicked={onClick}
      textColor={Colors.cyprus}
      border={border}
      solid={solid}
      background={background}
      enable={enable}
    />
  );

  const roomItems = roomSceneState?.selectedRoom?.getAllItems() ?? [];
  const layoutItems = (loadLayoutRes?.data.templateItems ?? []) as LayoutItem[];

  return (
    <>
      <CircularProgress
        isLoading={
          loadLayoutLoading ||
          saveLayoutLoading ||
          catalogsLoading ||
          roomsLoading ||
          editConfigLoading ||
          updateStatusLoading
        }
      />
      <Snackbar
        isOpen={showToast}
        setIsOpen={setShowToast}
        messageHeader={toast?.messageHeader}
        severity={toast?.severity || 'success'}
        message={toast?.message || ''}
        autoHideDuration={toast?.autoHideDuration}
      />
      {roomInfo.admin && (
        <Drawer
          variant="persistent"
          anchor="left"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.list}>
            <div className={classes.topContainer}>
              {activeMode === 'TemplateView' && (
                <div className={classes.flex} onClick={() => history.push(Routes.DEFAULT)}>
                  <ArrowBackIos className={classes.backIcon} />
                  <div className={classes.backText}>Back to Admin</div>
                </div>
              )}
              {(activeMode === 'CreateStyleAssociation' || activeMode === 'UpdateStyleAssociation') && (
                <div
                  className={classes.flex}
                  onClick={() => {
                    resetRoom(true);
                    setActiveMode('TemplateView');
                  }}
                >
                  <ArrowBackIos className={classes.backIcon} />
                  <div className={classes.backText}>Back to Template</div>
                </div>
              )}
            </div>
            {/* <div className={classes.stylesNavButton}>
              <ButtonMUI
                variant="contained"
                onClick={() =>
                  history.push({
                    pathname: Routes.ROOM_STYLES,
                    search: `?room_config_id=${loadLayoutRes?.data?.roomieRoomConfigId}`,
                  })
                }
                style={{
                  width: '245px',
                  borderRadius: '10px',
                  height: '55px',
                  background: Colors.paleTurquoise,
                  color: Colors.cyprus,
                }}
                startIcon={<StyleIcon fill={Colors.paleTurquoise} />}
              >
                Style This Template
              </ButtonMUI>
            </div> */}
            <div>
              {activeMode === 'CreateStyleAssociation' && (
                <AsyncAutocomplete
                  resetRoom={resetRoom}
                  addStyleItem={addStyleItem}
                  setIsEnableCatalogList={setIsEnableCatalogList}
                  setStyle={setStyle}
                />
              )}
              {/* {style && appliedStyleId && (
                <div className={classes.appliedStyleWrapper}>
                  <div>
                    <img height="60" width="90" src={style?.styleThumbnail} />
                  </div>
                  <div>
                    <h3>{style?.styleName}</h3>
                  </div>
                </div>
              )} */}
              {status && (
                <FormControl className={classes.formControl}>
                  <InputLabel id="select-status">Status</InputLabel>
                  <Select
                    value={status}
                    disabled={!authContext?.roomInfo?.isRoomieEmployee}
                    onChange={(e) => updateStatus(e.target.value as string)}
                    displayEmpty
                    labelId="select-status"
                    className={classes.select}
                  >
                    {STATUS_OPTIONS.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
            <div className={classes.header}>{loadLayoutRes?.data?.roomieConfigName || ''}</div>
            <div className={classes.buttonsWrapper}>
              <CopyButton isCopied={isCopied} onClick={copy} />
              {authContext?.roomInfo?.isRoomieEmployee && (
                <CustomButton
                  title="Edit Data"
                  padding="0px"
                  mTop="0px"
                  background={Colors.paleTurquoise}
                  fontSize="14px"
                  borderRadius="28px"
                  height="34px"
                  textColor={Colors.cyprus}
                  width="118px"
                  buttonClicked={() => setShowEditConfigModal(true)}
                />
              )}
            </div>
            {SHOW_BED_TOGGLE_CONFIGURATIONS && (
              <div className={classes.roomConfiguration}>
                <div className={classes.configurationText}>Room Configuration</div>
                {ROOM_CONFIGURATIONS.map((item) => (
                  <RadioField
                    key={item.key}
                    title={item.title}
                    value={roomConfigs[item.key] as string}
                    setValue={(value) => setValueInRoomConfigs(item.key, value)}
                    options={item.options}
                  />
                ))}
                <div className={classes.label}>Student count:</div>
                <InputFieldWithLabel
                  value={roomConfigs['studentCount']}
                  onChange={(e) => {
                    setIsError(false);
                    setValueInRoomConfigs('studentCount', Number(e.target.value));
                  }}
                  placeholder="Enter number"
                  italicPlaceholder
                  isError={isError}
                  padding="10px 11px"
                  width="140px"
                  mTop="8px"
                  type="number"
                  background={Colors.plain_white}
                  borderRadius="2px"
                />
              </div>
            )}

            <Tabs
              scrollButtons="on"
              variant="scrollable"
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
            >
              <Tab label="Catalog" style={{ minWidth: 0 }} className={classes.tab} />
              <Tab label="Items" style={{ minWidth: 0 }} className={classes.tab} />
              <Tab label="Layout" style={{ minWidth: 0 }} className={classes.tab} />
              <Tab label="Associated Styles" style={{ minWidth: 0 }} className={classes.tab} />
            </Tabs>

            <TabDisplay value={tabValue} index={0}>
              <>
                {catalogs.map(
                  (catalog) =>
                    catalog.active &&
                    !isPillowType(catalog.itemName) && (
                      <UniversityCatalog
                        key={catalog.catalogId}
                        catalog={catalog || []}
                        addItem={addItem}
                        listRef={listRef}
                        isEnableCatalogList={isEnableCatalogList}
                      />
                    ),
                )}
              </>
            </TabDisplay>

            <TabDisplay value={tabValue} index={1}>
              <>
                {sortBy(roomItems, 'itemId')
                  .filter((item) => !item.parent)
                  .map((item) => (
                    <RoomItemTree
                      key={item.itemId}
                      catalogs={catalogs}
                      item={item}
                      items={roomItems}
                      onChange={refresh}
                    />
                  ))}
              </>
            </TabDisplay>

            <TabDisplay value={tabValue} index={2}>
              <>
                {sortBy(layoutItems, 'itemId')
                  .filter(
                    (layoutItem: LayoutItem) =>
                      !layoutItem.parentItemId || !layoutItems.find((item) => item.itemId === layoutItem.parentItemId),
                  )
                  .map((layoutItem: LayoutItem) => (
                    <LayoutItemTree
                      key={layoutItem.itemId}
                      catalogs={catalogs}
                      layoutItem={layoutItem}
                      layoutItems={layoutItems}
                    />
                  ))}
              </>
            </TabDisplay>

            <TabDisplay value={tabValue} index={3}>
              {appliedStyles &&
                Array.isArray(appliedStyles) &&
                appliedStyles.map((style: ConfigStyle, index: number) => (
                  <AppliedStyles
                    style={style}
                    key={index}
                    addStyleItem={addStyleItem}
                    getAssociatedStyles={getAssociatedStyles}
                    setActiveMode={setActiveMode}
                    resetRoom={resetRoom}
                    loadLayout={loadLayoutOnDelete}
                  />
                ))}
            </TabDisplay>
          </div>

          <div className={classes.buttons}>
            {showButton && (
              <Button
                title={'Remove Item'}
                enable={!isEnableCatalogList}
                border={`1px solid ${Colors.frenchPass}`}
                onClick={removeElement}
              />
            )}
            {activeMode === 'TemplateView' && (
              <Button title="Save Room" solid onClick={saveRoom} background={Colors.paleTurquoise} />
            )}
            {activeMode === 'CreateStyleAssociation' && (
              <Button
                enable={style.styleId}
                title="Associate Style"
                solid
                onClick={createAssociateStyle}
                background={Colors.paleTurquoise}
              />
            )}
            {activeMode === 'UpdateStyleAssociation' && (
              <Button
                title="Update Associated Style"
                solid
                onClick={createAssociateStyle}
                background={Colors.paleTurquoise}
              />
            )}
            <Button title="Reset Room" onClick={resetRoom} border={`1px solid ${Colors.frenchPass}`} />
          </div>
        </Drawer>
      )}

      {!!loadLayoutRes?.data?.templateItems && (
        <RoomViewer
          isSharedUser={false}
          drawerOpen={true}
          onItemSelected={(ItemModel: any) => {
            onItemSelected(ItemModel);
          }}
          onMeasureTool={onMeasureTool}
          setIsLoaded={setIsLoaded}
          roomLoaded={true}
          setRoomSceneState={setRoomSceneStateRef}
          buildingName={loadLayoutRes?.data?.roomieConfigName}
          roomModelUrl={loadLayoutRes?.data?.roomie_model_url}
          items={layoutConfigToRoomItems(loadLayoutRes?.data?.templateItems)}
        />
      )}
      {showTooltip && roomInfo.admin && <CustomTooltip />}
      {isMeasuring && (
        <div className={classes.ButtonBarBottom}>
          <CustomIconButtonSecondary
            title="Clear measurement"
            buttonPressed={() => clearMeasurementPressed()}
            setActiveValue={(item) => setClearMeasurementHovered(item)}
            active={isMeasuring}
            icon={<CancelIcon fill={clearMeasurementHovered ? Colors.plain_white : Colors.hilighted_text} />}
          />
        </div>
      )}

      {activeMode === 'TemplateView' && (
        <div className={classes.createStyleButton}>
          <Button
            title="Create Style Association"
            borderRadius="6px"
            solid
            onClick={() => {
              setActiveMode('CreateStyleAssociation');
            }}
            background={Colors.paleTurquoise}
            width="250px"
          />
        </div>
      )}
      <RightMeasurementButton roomScene={roomSceneState} activateMeasurement={showMeasureButton} />
      <RoomViewerOnScreenText textToDisplay={onScreenTextvalue} color={Colors.plain_black} />
      <EditConfigDialog
        isOpen={showEditConfigModal}
        setIsOpen={setShowEditConfigModal}
        configInfo={configInfo}
        setConfigInfo={setConfigInfo}
        saveConfig={saveConfig}
        closeDialog={closeDialog}
      />
      <PhotoViewerDialog
        isOpen={showModal}
        setIsOpen={setShowModal}
        url={loadLayoutRes?.data?.panoUrl}
        buildingName={loadLayoutRes?.data?.roomieConfigName || ''}
      />
      <div className={classes.photoButton}>
        <Button
          title="360 Photo"
          borderRadius="6px"
          solid
          onClick={() => setShowModal(true)}
          background={Colors.paleTurquoise}
          width="150px"
        />
      </div>
      {authContext?.roomInfo?.isRoomieEmployee && <div className={classes.typeText}>{loadLayoutRes?.data?.type}</div>}
      <div className={classes.bottomButtons}>
        {previousTemplate && (
          <Button
            title="Previous"
            solid
            onClick={async () => await renderTemplate(previousTemplate)}
            background={Colors.paleTurquoise}
            width="150px"
          />
        )}
        {nextTemplate && (
          <Button
            title="Next"
            solid
            onClick={async () => await renderTemplate(nextTemplate)}
            background={Colors.paleTurquoise}
            width="150px"
          />
        )}
      </div>
    </>
  );
};

export default AdminPortal;

function TabDisplay({ children, value, index }: PropsWithChildren<{ value: number; index: number }>) {
  if (value !== index) {
    return null;
  }

  return <div style={{ paddingTop: 16 }}>{children}</div>;
}

const useModalStyles = makeStyles((theme) => ({
  paper: {
    position: 'fixed',
    minWidth: 500,
    maxWidth: '100vw',
    overflowX: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function RoomItemTree({
  item,
  items,
  catalogs,
  onChange,
}: {
  item: ItemModel;
  items: ItemModel[];
  catalogs: UniversityCatalogProps[];
  onChange: () => void;
}) {
  const classes = useModalStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const children = item.getChildren();
  const hasChildren = children.length > 0;
  const itemName = catalogs
    .flatMap((catalog) => catalog.catalogItems)
    .find((catalogItem) => catalogItem.catalogItemId === item.variantId)?.itemVariantName;

  return (
    <Accordion expanded={hasChildren ? undefined : false} style={{ flexGrow: 1 }}>
      <AccordionSummary
        expandIcon={hasChildren ? <ExpandMoreIcon /> : null}
        style={{ paddingInline: 6, fontSize: 'small' }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              item.remove();
              onChange();
            }}
          >
            <Delete fontSize="small" />
          </IconButton>

          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            <Info fontSize="small" />
          </IconButton>

          <span>
            {itemName}{' '}
            <i title={item.itemId} style={{ color: 'grey' }}>
              ({item.itemId.slice(0, 5)}...)
            </i>
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        {sortBy(children, 'itemId').map((child) => (
          <RoomItemTree key={child.itemId} item={child} items={items} catalogs={catalogs} onChange={onChange} />
        ))}
      </AccordionDetails>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={classes.paper}>
          <pre style={{ maxWidth: '100%' }}>{JSON.stringify(item.props, null, 2)}</pre>
        </div>
      </Modal>
    </Accordion>
  );
}

function LayoutItemTree({
  layoutItem,
  layoutItems,
  catalogs,
}: {
  layoutItem: LayoutItem;
  layoutItems: LayoutItem[];
  catalogs: UniversityCatalogProps[];
}) {
  const classes = useModalStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const children = layoutItems.filter((item) => item.parentItemId === layoutItem.itemId) ?? [];
  const hasChildren = children.length > 0;
  const itemName = catalogs
    .flatMap((catalog) => catalog.catalogItems)
    .find((catalogItem) => catalogItem.catalogItemId === layoutItem.variantId)?.itemVariantName;

  return (
    <Accordion expanded={hasChildren ? undefined : false} style={{ flexGrow: 1 }}>
      <AccordionSummary
        expandIcon={hasChildren ? <ExpandMoreIcon /> : null}
        style={{ paddingInline: 6, fontSize: 'small' }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            <Info fontSize="small" />
          </IconButton>

          <span>
            {itemName}{' '}
            <i title={layoutItem.itemId} style={{ color: 'grey' }}>
              ({layoutItem.itemId.slice(0, 5)}...)
            </i>
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        {sortBy(children, 'itemId').map((child) => (
          <LayoutItemTree key={child.itemId} layoutItem={child} layoutItems={layoutItems} catalogs={catalogs} />
        ))}
      </AccordionDetails>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={classes.paper}>
          <pre style={{ maxWidth: '100%' }}>{JSON.stringify(layoutItem, null, 2)}</pre>
        </div>
      </Modal>
    </Accordion>
  );
}
